import store     from '@/store/index'
import constants from '@/commons/constants'

export default async function notAuthenticatedMiddleware({ next }) {

    let currentUser = store.getters['sessionData/currentUser']

    return await currentUser
        ? await next({ name: constants.HOME_ROUTE_NAME })
        : next()

}