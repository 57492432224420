import axios  from 'axios'
import router from '@/router'

const axiosApiInstance = axios.create()

axiosApiInstance.interceptors.request.use(
    config => {

        config.withCredentials = true

        config.baseURL = process.env.VUE_APP_POS_API_DOMAIN

        config.headers = {
            'Accept':       'application/json',
            'Content-Type': 'application/json'
        }

        return config

    }, error => {

        return Promise.reject(error)

    })

axiosApiInstance.interceptors.response.use(
    response => {

        return response

    }, async function (error) {

        if(! [401, 403, 422, 429].includes(error.response.status))
            return await router.push('/error')

        return Promise.reject(error)

    })

export default axiosApiInstance
