<template>
  <router-view v-slot="{ Component }">
    <transition appear name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style>
  .fade-enter-active {
    transition: opacity .9s ease;
  }
  .fade-enter-from{
    transition: none;
    opacity: 0;
  }
</style>
