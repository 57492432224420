import store     from '@/store'
import PosApi    from '@/api/PosApi'
import constants from '@/commons/constants'

export default {
    async index() {
        return await PosApi.get(constants.SESSION_DATA_API_ROUTE_NAME)
            .then(response => {

                return response.data

            }).catch(error => {

                return error.response.data

            })
    },

    async loadToVuex() {
        let response = Object.assign({}, await this.index())
        store.commit('sessionData/setCurrentUser', response.success ? response.user : null)
    },

    async loadAppData() {
        await this.loadToVuex()
    }
}