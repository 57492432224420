const CASH  = 'cash'
const CARD  = 'card'
const CHECK = 'check'

const CONFIRMED_STATUS = 'confirmed'

const ROOT_ROUTE_NAME      = 'root'
const HOME_ROUTE_NAME      = 'home'
const SALE_ROUTE_NAME      = 'sale'
const LOGIN_ROUTE_NAME     = 'login'
const ERROR_ROUTE_NAME     = 'error'
const PAYMENT_ROUTE_NAME   = 'payment'
const NOT_FOUND_ROUTE_NAME = 'not-found'

const LOGIN_API_ROUTE_NAME                = 'login'
const LOGOUT_API_ROUTE_NAME               = 'logout'
const SALE_LIST_API_ROUTE_NAME            = 'api/sale'
const SALE_SUBMIT_API_ROUTE_NAME          = 'api/sale'
const CASH_LIST_API_ROUTE_NAME            = 'api/cash'
const PAYMENT_LIST_API_ROUTE_NAME         = 'api/payment'
const PRODUCT_LIST_API_ROUTE_NAME         = 'api/product'
const SESSION_DATA_API_ROUTE_NAME         = 'api/session-data'
const SANCTUM_CSRF_COOKIE_API_ROUTE_NAME  = 'sanctum/csrf-cookie'
const PAYMENT_CHANNEL_LIST_API_ROUTE_NAME = 'api/payment-channel'

export default {
    CASH,
    CARD,
    CHECK,

    CONFIRMED_STATUS,

    ROOT_ROUTE_NAME,
    HOME_ROUTE_NAME,
    SALE_ROUTE_NAME,
    ERROR_ROUTE_NAME,
    LOGIN_ROUTE_NAME,
    PAYMENT_ROUTE_NAME,
    NOT_FOUND_ROUTE_NAME,

    LOGIN_API_ROUTE_NAME,
    LOGOUT_API_ROUTE_NAME,
    CASH_LIST_API_ROUTE_NAME,
    SALE_LIST_API_ROUTE_NAME,
    SALE_SUBMIT_API_ROUTE_NAME,
    PAYMENT_LIST_API_ROUTE_NAME,
    PRODUCT_LIST_API_ROUTE_NAME,
    SESSION_DATA_API_ROUTE_NAME,
    SANCTUM_CSRF_COOKIE_API_ROUTE_NAME,
    PAYMENT_CHANNEL_LIST_API_ROUTE_NAME,
}