import { createApp } from 'vue'
import App           from '@/App.vue'
import store         from '@/store'
import router        from '@/router'
import VueHtmlToPaper from '@/commons/VueHtmlToPaper'

import '@/assets/css/tailwind.css'
import '@/assets/css/style.css'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueHtmlToPaper)

app.mount('#app')
