export default {
    namespaced: true,

    state: {
        currentUser: {}
    },

    getters: {
        currentUser (state) {
            return state.currentUser
        },
    },

    mutations: {
        setCurrentUser (state, value) {
            state.currentUser = value
        },
    }
}
