import {createRouter, createWebHashHistory} from 'vue-router'
import constants                            from '@/commons/constants'
import IndexMiddleware                      from '@/middlewares/Index'
import SessionDataService                   from '@/services/SessionDataService'
import AuthenticatedMiddleware              from '@/middlewares/AuthenticatedMiddleware'
import NotAuthenticatedMiddleware           from '@/middlewares/NotAuthenticatedMiddleware'

const routes = [
  {
    path: '/',
    name: constants.ROOT_ROUTE_NAME,
    component: () => import('@/views/LayoutView'),
    meta: {
      middleware: [AuthenticatedMiddleware]
    },
    children:[
      {
        path: '',
        redirect: { name: constants.HOME_ROUTE_NAME },
        meta: {
          middleware: [AuthenticatedMiddleware]
        },
      },
      {
        path: constants.HOME_ROUTE_NAME,
        name: constants.HOME_ROUTE_NAME,
        component: () => import('@/views/HomeView'),
        meta: {
          middleware: [AuthenticatedMiddleware]
        }
      },
      {
        path: constants.SALE_ROUTE_NAME,
        name: constants.SALE_ROUTE_NAME,
        component: () => import('@/views/SaleView'),
        meta: {
          middleware: [AuthenticatedMiddleware]
        }
      },
      {
        path: constants.PAYMENT_ROUTE_NAME,
        name: constants.PAYMENT_ROUTE_NAME,
        component: () => import('@/views/PaymentView'),
        meta: {
          middleware: [AuthenticatedMiddleware]
        }
      },
    ]
  },
  {
    path: '/login',
    name: constants.LOGIN_ROUTE_NAME,
    component: () => import('@/views/LoginView'),
    meta: {
      middleware: [NotAuthenticatedMiddleware]
    }
  },
  {
    path: '/error',
    name: constants.ERROR_ROUTE_NAME,
    component: () => import('@/views/ErrorView')
  },
  {
    path: '/:catchAll(.*)',
    name: constants.NOT_FOUND_ROUTE_NAME,
    redirect: { name: constants.HOME_ROUTE_NAME }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {

  if (! to.meta.middleware)
    return next()

  await SessionDataService.loadAppData()

  const middleware = to.meta.middleware

  const context = { from, next, router, to }

  await middleware[0]({...context, next: await IndexMiddleware(context, middleware, 1)})

})

router.afterEach(() => {

  if(! process.env.VUE_APP_DEBUG)
    console.clear()

})

export default router