export default async function nextFactory(context, middleware, index) {

    const subsequentMiddleware = middleware[index]

    if(! subsequentMiddleware)
        return context.next

    return async () => {

        await subsequentMiddleware({ ...context, next: await nextFactory(context, middleware, index + 1) })

    }

}